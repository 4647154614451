import React, {useContext, useEffect, useState} from "react";
import {BottomSheet} from "react-spring-bottom-sheet";
import Slider from "react-slick";

import {classNames} from "../../helper";
import {AdditionalProduct} from "../sections/catalog/ProductCard";
import {ProductButton} from "../sections/catalog/Button";
import {AppContext} from "../../store/context";
import {STATIC_HOST_PRODUCT} from "../../const";

import back from '../../static/icons/catalog/back-icon.svg'
import cross from "../../static/icons/cross.svg";
import spice from "../../static/icons/catalog/spice-card.svg";
import spicyAll from "../../static/icons/catalog/spicy-all.svg";
import hit from "../../static/icons/catalog/hit-card.svg";

import styles from './Product.module.css'
import {ProductModalWrapper} from "./Wrapper";

export const ResponsiveProductModal = ({isOpen, product, onDismiss}) => {
    const {context} = useContext(AppContext);

    const [open, setOpen] = useState(false);
    const [display, setDisplay] = useState(false);

    useEffect(() => {
        if (isOpen === true) {
            setDisplay(isOpen);
            setTimeout(() => {
                setOpen(isOpen)
                context.modals.product.dispatch({type: "setOption", payload: null})
                context.modals.product.dispatch({type: "open", payload: product})
                context.common.dispatch({
                    type: 'setSeo',
                    payload: {
                        title: product.seo.title,
                        description: product.seo.description,
                    }
                })
            }, 10)
        } else {
            setOpen(isOpen);
            setTimeout(() => {
                setDisplay(isOpen)
                context.modals.product.dispatch({type: "close"})
            }, 400)
        }

        const available = context.cart.state.delivery.zone.group ? !!product?.zones.find((el) => {
            return el === context.cart.state.delivery.zone.group
        }) : true


        context.modals.product.dispatch({type: "setAvailable", payload: available})
    }, [isOpen])

    return context.settings.isMobile
        ? <BottomSheet
            open={isOpen}
            blocking={false}
            expandOnContentDrag={true}
            className={"withPicture productCard"}
            onDismiss={onDismiss}
            snapPoints={({maxHeight}) => maxHeight - 100}
            footer={<ProductBuyBlock/>}
        >
            <ProductModal onDismiss={onDismiss}/>
        </BottomSheet>
        : <ProductModalWrapper isOpen={isOpen} onDismiss={onDismiss}>
            <div className={classNames(styles.modal, (open ? styles.modalOpen : ''))}
                 style={{display: display ? "flex" : "none"}}>
                <div className={styles.cross} onClick={() => onDismiss()}>
                    <img src={cross} alt=""/>
                </div>
                <ProductModal onDismiss={onDismiss}/>
            </div>
        </ProductModalWrapper>
}

const ProductBuyBlock = () => {
    const {context} = useContext(AppContext)
    const available = context.modals.product.state.available;
    const priceOld = context.modals.product.state.product?.prices.old;
    const priceActual = context.modals.product.state.product?.prices.actual;
    const optionPrice = context.modals.product?.state.option?.price;

    return <div className={styles.buyBlock}>
        {available ?
        <div className={styles.price}>
            {priceOld || optionPrice ?
            <div className={styles.priceDiscount}>
                {priceOld ? priceOld : priceActual}
            </div> : null}
            <div className={styles.priceActual}>
                {optionPrice ? optionPrice + priceActual : priceActual} ₽
            </div>
        </div> : null}
        <div className={styles.addButton}>
            <ProductButton
                disabled={context.modals.product.state.product?.options.length > 0 && !context.modals.product.state.option}
                option={context.modals.product.state.option}
                product={context.modals.product.state.product}
            />
        </div>
    </div>
}

export const ProductModal = ({onDismiss}) => {
    const {context} = useContext(AppContext)

    const option = context.modals.product.state.option
    const available = context.modals.product.state.available
    const product = context.modals.product.state.product
    const nutrition = product?.content.nutrition

    const [optionTitle, setOptionTitle] = useState()

    useEffect(() => {
        if (context.modals.product.state.product?.options.length > 0) {
            context.modals.product.state.product?.options.forEach(el => {

                if(context.modals.product.state.product?.category.slug == 'obedi') {
                    setOptionTitle("Выберите суп")
                } else {
                    switch (el.group) {
                        case "soup":
                            setOptionTitle("Выберите бульон")
                            return
                        case "spicy":
                            setOptionTitle("Выберите остроту")
                            return
                        case "cook-type":
                            setOptionTitle("Как приготовить")
                            return
                        case "temp":
                            setOptionTitle("Выберите температуру")
                            return
                        case "sause":
                            setOptionTitle("Выберите соус")
                            return
                        default:
                            setOptionTitle(null)
                    }
                }
            })

            const inCart = Object.values(context.cart.state.products).find(el => {
                return el.id == product.id
            })

            if (inCart) {
                selectOption(inCart?.option)
            }

            if (context.settings.isMobile && context.modals.product.state.openScrolling) {
                context.modals.product.dispatch({type: 'setOpenScrolling', payload: false})

                const itemContent = document.querySelector('div[class^="Product_modifiers__"]');
                if (itemContent) {
                    setTimeout(() => {
                        itemContent.scrollIntoView({behavior: 'smooth', block: 'start'});
                    }, 400)
                }
            }
        }

    }, [context.modals.product.state.product])

    const selectOption = (el) => {
        context.modals.product.dispatch({type: "setOption", payload: el})
    }

    const getRecommendations = (product) => {
        let category = [];

        if (product) {
            category = context.common.state.categories.filter(item => item.title == product.category.title);
        }

        return category.length ? category[0].recommendations : [];
    }

    const currentDayModifier = (options) => {
        const currentDay = new Date().getDay();

        if (currentDay === 0 || currentDay === 6) {
            return options;
        }

        const DAY_OPTIONS_UIDS = {
            1 : 'd0135a5c-313e-423d-8a34-3c156867e223',
            2 : '25dfffdb-070b-4b5d-96ca-effa0127fa96',
            3 : '92322ba1-2bc2-48da-a8ed-b459204b4dd1',
            4 : 'b4c8b74f-27c7-475a-a7c3-4c666772639d',
            5 : '490197b1-ca90-4a88-86c6-201c40a2a205',
        }

        const OPTIONS_UIDS = Object.values(DAY_OPTIONS_UIDS);

        const productOptionsFilter = options.filter(option => {
            if (option.uid === DAY_OPTIONS_UIDS[currentDay]) {
                return true;
            }

            return !OPTIONS_UIDS.includes(option.uid);
        });

        return productOptionsFilter;
    }

    const showModifiers = () => {
        let productOptions = product.options;

        if (product?.category.slug === 'obedi') {
            productOptions = currentDayModifier(productOptions);
        }

        return (
            <div className={styles.modifiers}>
                <div className={styles.modifiersTitle}>{optionTitle || 'Выберите вкус'}:</div>
                <div className={styles.modifiersOptions}>
                    {productOptions.map(el => {
                        return <div
                            className={classNames(styles.modifier, el.id === option?.id ? styles.modifierActive : '')}
                            onClick={() => selectOption(el)}>
                            <span className={styles.modifiersName}>{el.name}</span>
                            {el.price ?
                            <span className={styles.modifiersPrice}>{`+${el.price}`}&nbsp;&#8381;</span> : null}
                        </div>
                    })}
                </div>
            </div>
        )
    }

    return <>
        <div className={styles.head}>
            <div className={styles.back} onClick={() => onDismiss()}>
                <div className={styles.backIcon}>
                    <img src={back} alt=""/>
                </div>
                <div className={styles.backText}>{product?.category.title}</div>
            </div>
        </div>
        <div className={styles.product}>
            <div className={styles.image}>
                <img src={STATIC_HOST_PRODUCT + product?.image?.source}
                     alt={product?.image?.alt}/>
            </div>
            <div className={styles.about}>
                <div className={styles.title}>
                    <div className={styles.titleText}>{product?.title}</div>
                    <div className={styles.titleIcons}>
                        {product?.tags.find(e => e.slug === 'ikonkaostroe') ?
                            <img width="15" height="24" src={spicyAll} alt=""/> : null}
                        {product?.tags.find(e => e.slug === 'ikonkaostrotaregyliryetsya') ?
                            <img src={spice} alt=""/> : null}
                        {product?.tags.find(e => e.slug === 'ikonkahiti') ?
                            <img src={hit} alt=""/> : null}
                    </div>
                </div>
                <div className={styles.weight}>{product?.content.weight}</div>
                {product?.content.description ?
                    <div className={styles.description}
                         dangerouslySetInnerHTML={{
                             __html: product?.content.description
                         }}/>
                    : null}
                {product?.content.ingredients ?
                    <div className={styles.ingredients}>
                        <span>Состав:</span>
                        <div dangerouslySetInnerHTML={{__html: product?.content.ingredients}}/>
                    </div> : null}
                {nutrition?.energy ?
                    <div className={styles.nutrition}><span>КБЖУ: </span>
                        {`${nutrition.energy} / ${nutrition.protein} / ${nutrition.fat} / ${nutrition.carbohydrates} `}
                    </div> : null}
                {product?.options.length > 0 ? showModifiers() : null }
                {!context.settings.isMobile
                    ? <ProductBuyBlock product={product} option={option} available={available}/>
                    : null}
            </div>
        </div>
        <div className={styles.additional}>
            <div className={styles.additionalTitle}>не забудьте добавить:</div>
            <div className={styles.additionalGrid}>
                <Slider {...{
                    slidesToShow: context.settings.isMobile ? 2 : 3,
                    speed: 500,
                    arrows: false,
                    draggable: true,
                    variableWidth: false,
                    className: styles.slider,
                }}>
                    {
                        getRecommendations(product).map(el => {
                        return <AdditionalProduct product={el}/>
                    })}
                </Slider>
            </div>
        </div>
    </>

}
