import {generatePath, Link, useHistory} from "react-router-dom";

import arrowRight from '../../../static/icons/catalog/arrow_right.svg'

import styles from "./PromoCategory.module.css"
import {useContext, useEffect, useState} from "react";
import {AppContext} from "../../../store/context";
import {STATIC_HOST_BANNERS} from "../../../const";
import {classNames} from "../../../helper";
import {publicRoutes} from "../../../routing";
import {RedButton} from "../../common/Buttons";

export const BannerSection = () => {

    const BANNER_TYPE_SMALL = 'delivery_main_small';

    const {context} = useContext(AppContext);

    const history = useHistory()

    const banners = getSortedBanners(
        context.common.state.banners.delivery?.small || [],
        (context.common.state.banners.delivery?.big || []).slice(2),
    );

    return <div className={styles.promoInner}>
        <div className={styles.promoRow}>
            {context.settings.isMobile ? <PromoCategoryExpandedMobile/> : <PromoCategoryExpanded/>}
        </div>
        <div className={styles.promoRow}>
            {banners.map(el => {
                return <div className={`${styles.card} ${el.type !== BANNER_TYPE_SMALL ? styles.cardBig : ''} ${el?.text ? styles.cardWithText : ''}`}>
                    <div className={styles.cardImage}>
                        <img onClick={() => history.push(el.link)}
                             src={STATIC_HOST_BANNERS + el.image.source} alt={el.image?.alt}/>
                    </div>
                    {context.settings.isMobile && el?.text
                        ? <>
                            <div className={styles.cardExpandedText}>
                                <div className={styles.cardExpandedHeader}>
                                    <div className={styles.cardTitle}>{el.title}</div>
                                    <div className={styles.cardLink}>
                                        <Link to={el.link}>перейти в раздел</Link>
                                    </div>
                                </div>
                                <div onClick={() => history.push(el.link)} className={styles.cardText}
                                     dangerouslySetInnerHTML={{__html: el.text}}/>
                                <div className={styles.cardArrow}>
                                    <img src={arrowRight}
                                         onClick={() => history.push(el.link)}/>
                                </div>
                            </div>
                        </>
                    : <div className={styles.cardHeader}>
                            <div className={styles.cardTitle} onClick={() => history.push(el.link)}>
                                <span>{el.title}</span>
                                {context.settings.isMobile ? <span><img src={arrowRight} alt=""/></span> : null}
                            </div>
                            <div className={styles.cardLink}>
                                <Link to={el.link}>перейти в раздел</Link>
                            </div>
                        </div>
                    }
                </div>
            })}
        </div>
    </div>
}

export const getSortedBanners = (small, big) => {

    const combined = [];

    let smallIndex = 0;
    let bigIndex = 0;

    while (bigIndex < big.length || smallIndex < small.length) {
        if (bigIndex < big.length) {
            combined.push(big[bigIndex]);
            bigIndex++;
        }

        for (let i = 0; i < 2 && smallIndex < small.length; i++) {
            combined.push(small[smallIndex]);
            smallIndex++;
        }
    }

    return combined;
}

export const PromoCategoryExpanded = () => {
    const {context} = useContext(AppContext)
    const history = useHistory()

    const [selected, setSelected] = useState(0);

    return <div className={styles.cardsExpanded}>
        {context.common.state.banners.delivery.big.slice(0, 2).map((el, i) => {
            return <>
                <div className={styles.cardImageExpanded} onClick={() => setSelected(i)}>
                    <img src={STATIC_HOST_BANNERS + el.image?.source} alt={el.image?.alt}/>
                </div>
                <div className={classNames(styles.cardExpandedText, selected === i ? styles.active : '')}>
                    <div className={styles.cardExpandedTextInner}>
                        <div className={styles.cardExpandedHeader}>
                            <div className={styles.cardTitle}>{el.title}</div>
                            <div className={styles.cardLink}>
                                <Link to={el.link}>перейти в раздел</Link>
                            </div>
                        </div>
                        <div className={styles.cardText} dangerouslySetInnerHTML={{__html: el.text}}/>
                        <div className={styles.cardArrow}>
                            <img src={arrowRight} onClick={() => history.push(el.link)}/>
                        </div>
                    </div>
                </div>
            </>
        })}
    </div>
}

export const PromoCategoryExpandedMobile = () => {
    const {context} = useContext(AppContext)
    const history = useHistory()

    const [selected, setSelected] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setSelected(prevSelected => (prevSelected === 0 ? 1 : 0));
        }, 5000);

        return () => clearInterval(interval);
    }, []);

    return <div className={classNames(styles.cardsExpanded, selected === 0 ? styles.first : styles.second)}>
        {context.common.state.banners.delivery.big.slice(0, 2).map((el, i) => {
            return <div className={styles.cardExpanded}>
                <div className={styles.cardImageExpanded} onClick={() => setSelected(i)}>
                    <img src={STATIC_HOST_BANNERS + el.image?.source} alt={el.image?.alt}/>
                </div>

            </div>
        })}
        <div className={styles.cardExpandedText}>
            <div className={styles.cardExpandedHeader}>
                <div className={styles.cardTitle}>{context.common.state.banners.delivery.big[selected]?.title}</div>
                <div className={styles.cardLink}>
                    <Link to={context.common.state.banners.delivery.big[selected]?.link}>перейти в раздел</Link>
                </div>
            </div>
            <div onClick={() => { history.push(context.common.state.banners.delivery.big[selected]?.link)}} className={styles.cardText}
                 dangerouslySetInnerHTML={{__html: context.common.state.banners.delivery.big[selected]?.text}}/>
            <div className={styles.cardArrow}>
                <img src={arrowRight}
                     onClick={() => history.push(context.common.state.banners.delivery.big[selected]?.link)}/>
            </div>
        </div>
    </div>
}


