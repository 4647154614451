import {useContext, useEffect, useRef, useState} from "react";
import {Link, generatePath, useRouteMatch} from "react-router-dom";

import {publicRoutes} from "../../../routing";
import {AppContext} from "../../../store/context";
import {classNames} from "../../../helper";

import im from '../../../static/icons/catalog/im.svg'
import arrowdown from '../../../static/icons/arrow_down.svg'
import arrowup from '../../../static/icons/arrow_up-gray.svg'
import styles from './Menu.module.css'

export const Menu = () => {
    const {context} = useContext(AppContext)
    const route = useRouteMatch()

    return context.settings.isMobile ? <MobileMenuStatic/> : <div className={styles.menu}>
        <div className={styles.menuHeader}>
            <div className={styles.menuHeaderIcon}>메뉴</div>
            <div className={styles.menuHeaderText}>МЕНЮ</div>
        </div>
        <div className={styles.menuInner}>

            <Link to={'/skidki'} className={classNames(
                styles.menuInnerItem,
                styles.menuInnerItemRed,
                route.path.startsWith("/skidki") ? styles.active : '')}
            >
                <span>Акции</span>
            </Link>

            {context.common.state.categories.map(el => {
                return <Link to={generatePath(publicRoutes.deliveryCategory.path[0], {
                    slug: el.slug
                })} className={classNames(
                    styles.menuInnerItem,
                    route.path.startsWith("/delivery/:slug") &&
                    route.params.slug === el.slug
                        ? styles.active
                        : '')}>
                    <span>{el.title}</span>
                </Link>
            })}
            <div className={styles.menuOnline}>
                <Link to={"/korean-market"} className={
                    classNames(
                        styles.menuInnerItem,
                        styles.menuInnerItemRed,
                        route.path.startsWith("/korean-market")
                            ? styles.active
                            : '')
                }>
                    <img src={im} alt=""/>
                    <span>интернет-магазин</span>
                </Link>
            </div>
        </div>
    </div>
}

export const MobileMenuStatic = () => {
    const {context} = useContext(AppContext)
    const route = useRouteMatch()
    const menuRef = useRef(null);

    const [scroll, setScroll] = useState(false)

    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 95);
        });
    }, []);


    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                context.modals.menu.dispatch({ type: "close" });
            }
        };

        if (context.modals.menu.state.open) {
            document.addEventListener("click", handleOutsideClick);
        } else {
            document.removeEventListener("click", handleOutsideClick);
        }

        return () => document.removeEventListener("click", handleOutsideClick);
    }, [context.modals.menu.state.open]);

    return <div className={classNames(styles.menu, scroll ? styles.clearfix : '')}>
        <div className={classNames(styles.menuHeader, scroll ? styles.fixed : '')} onClick={() => {
            context.modals.menu.dispatch({type: context.modals.menu.state.open ? "close" : "open"})
        }}>
            <div className={styles.menuHeaderIcon}>메뉴</div>
            <div className={styles.menuHeaderText}>МЕНЮ</div>
            <div className={styles.menuHeaderArrow}>
                <img src={context.modals.menu.state.open ? arrowup : arrowdown} alt=""/>
            </div>
            <div className={classNames(styles.menuInner, context.modals.menu.state.open ? styles.open : '')}
                 ref={menuRef}
            >

                <Link to={'/skidki'} className={classNames(
                    styles.menuInnerItem,
                    styles.menuInnerItemRed,
                    route.path.startsWith("/skidki") ? styles.active : '')}
                >
                    <span className={styles.menuInnerItemRed}>Акции</span>
                </Link>

                {context.common.state.categories.map(el => {
                    return <Link to={generatePath(publicRoutes.deliveryCategory.path[0], {slug: el.slug})}
                                 className={classNames(styles.menuInnerItem,
                                     route.path.startsWith("/delivery/:slug") &&
                                     route.params.slug === el.slug
                                         ? styles.active
                                         : '')
                                 }>
                        <span>{el.title}</span>
                    </Link>
                })}
                <div className={styles.menuOnline}>
                    <Link to={"/korean-market"} className={
                        classNames(
                            styles.menuInnerItem,
                            route.path.startsWith("/korean-market")
                                ? styles.active
                                : '')
                    }>
                        <img src={im} alt=""/>
                        <span>интернет-магазин</span>
                    </Link>
                </div>
            </div>
        </div>
    </div>
}
